<template>
    <div v-if="$store.state.isModalOpenTwo" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-lg modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Staff Register' }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">

                    <div class="col-11 py-1 mx-auto">
                      <div class="row">
                         <div class="col-md-4 col-12">
                           <div class="mb-1">
                             <label class="form-label" for="fullName"> <span class="required">*</span> Full Name</label>
                             <input
                                 name="amount" @input="formError.full_name = ''"
                                 type="text" v-model.trim="formData.full_name"
                                 class="form-control"
                             />
                             <transition name="fade">
                               <span v-if="formError.full_name" class="invalid-feedback d-block qcont">{{ formError.full_name }}</span>
                             </transition>
                           </div>
                         </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="mobileNo"> <span class="required">*</span> Mobile No (017xxxxxxxx)</label>
                            <input
                                name="mobile" @input="formError.mobile_no = ''"
                                type="text" v-model.trim="formData.mobile_no"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.mobile_no" class="invalid-feedback d-block qcont">{{ formError.mobile_no }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="staff"><span class="required">*</span> Referrer Staff Id</label>
                            <input
                                name="Referrer Staff Id"
                                @input="getReferrerIdByStaffId"
                                type="text" v-model="referrerStaffId"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="referrerStaffName" class="fw-bold d-block qcont">Referrer Staff: {{ referrerStaffName }}</span>
                            </transition>
                            <transition name="fade">
                              <span v-if="formError.referrer_staff_id" class="invalid-feedback d-block qcont">{{ formError.referrer_staff_id }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="mobileNo">Email</label>
                            <input
                                name="mobile" @input="formError.email = ''"
                                type="text" v-model.trim="formData.email"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.email" class="invalid-feedback d-block qcont">{{ formError.email }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="mobileNo">Father Name</label>
                            <input
                                name="mobile" @input="formError.father_name = ''"
                                type="text" v-model.trim="formData.father_name"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.father_name" class="invalid-feedback d-block qcont">{{ formError.father_name }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="mobileNo">Mother Name</label>
                            <input
                                name="mobile" @input="formError.mother_name = ''"
                                type="text" v-model.trim="formData.mother_name"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.mother_name" class="invalid-feedback d-block qcont">{{ formError.mother_name }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="mobileNo">Address</label>
                            <input
                                name="mobile" @input="formError.location = ''"
                                type="text" v-model.trim="formData.location"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.location" class="invalid-feedback d-block qcont">{{ formError.location }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="mb-1">
                            <label class="form-label" for="nid">NID No</label>
                            <input
                                name="mobile" @input="formError.nid = ''"
                                type="text" v-model.trim="formData.nid"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.nid" class="invalid-feedback d-block qcont">{{ formError.nid }}</span>
                            </transition>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="designation">Designation</label>
                            <input
                                name="mobile" @input="formError.designation = ''"
                                type="text" v-model.trim="formData.designation"
                                class="form-control"
                            />
                            <transition name="fade">
                              <span v-if="formError.designation" class="invalid-feedback d-block qcont">{{ formError.designation }}</span>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 text-center">
                        <button :disabled="loading" @click="submit" type="button" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            Submit
                        </button>
                        <button @click.prevent="onClickClose" class="btn btn-outline-secondary waves-effect">
                            Cancel
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import handleChartAccount from '@/services/modules/chartAccount'
import Loader from "@/components/atom/LoaderComponent";
import handleRole from "@/services/modules/role";
import handleHospital from "@/services/modules/hospital";
import handleCompanyContact from "@/services/modules/companyContact";
import { computed, inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';

const props = defineProps(['employees', 'onCreateProfile']);
const emit = defineEmits(['onCreateProfile']);
const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const referrerStaffId = ref(null);
const referrerStaffName = ref(null);
const loading = ref(false);
const loader = ref(false);
const {fetchHome} = handleChartAccount()
const {fetchCompanyDefaultRoles} = handleRole();
const { storeCompanyContact, storeAdditionalContactItems, fetchContactProfileBySerialNo } = handleCompanyContact();
const {dateOfBarthConvertToAge} = handleHospital()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const formData = reactive({
    full_name: null,
    mobile_no: null,
    email: null,
    father_name: null,
    mother_name: null,
    location: null,
    nid: null,
    designation: null,
    contact_owner_id: null,
    company_rules: [],
    company_roles_data: [],
    contact_id: '',
    contact_type: 'person',
    active_tab: 'basic-info',
    profile_type: 'user',
})

const formError = reactive({
    full_name: '',
    mobile_no: '',
})

const submit = async() => {
    if(checkValidation()) return;
    const query = getQuery();
    loading.value = true;

    await storeCompanyContact(formData, query).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message)
        const {mobile_no, contact_owner_id} = formData
        const resData = res.data;
        saveAdditionalContactItems(res.data.id, { mobile_no, contact_owner_id }, query)
        resData.mobile_no = mobile_no;
        emit('onCreateProfile', resData);
        onClickClose();
        showSuccess(res.message)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const saveAdditionalContactItems = async (contactId, keys, getQuery) => {
  await storeAdditionalContactItems(contactId, keys, getQuery).then(res => {
    loading.value = false
    if (!res.status) {
      return showError(res.message)
    }
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const checkValidation = () => {
    let flag = false;

    if(!formData.full_name){
        formError.full_name = 'Full name is required'
        flag = true;
    }

    if(!formData.mobile_no){
        formError.mobile_no = 'Mobile no is required'
        flag = true;
    }

    if (!formData.contact_owner_id){
      formError.referrer_staff_id = 'Referrer Staff Id is required'
      flag = true;
    }

    return flag;
}

const onClickClose = () => {
    $store.state.isModalOpenTwo = false;
}

const getReferrerIdByStaffId = () => {
  let companyQuery = `?company_id=${$route.params.companyId}&roles=employee`
  companyQuery += '&serial_no='+referrerStaffId.value;
  fetchContactProfileBySerialNo(companyQuery).then(res => {
    if (!res.status) {
      formData.contact_owner_id = null;
      referrerStaffName.value = null;
      return showError(res.message)
    }
    formData.contact_owner_id = res.data.id;
    referrerStaffName.value = res.data.full_name;
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

function getQuery() {
  return `?company_id=${$route.params.companyId}`;
}

onMounted(() => {
  const companyQuery = `?company_id=${$route.params.companyId}&roles=employee`

  Promise.all([
    fetchCompanyDefaultRoles(companyQuery).then((res) => {
        if(res.data) {
            res.data.map((item) => {
              formData.company_rules.push(item.id);
              formData.company_roles_data.push({ id: item.id });
            });
        }
    }),
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  })

})
</script>
