import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const pdfPrinter = () => {
    const store = useStore();
    const {commaFormat} = figureFormatter();
    const {getHeader, getFooter} = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, staffRegister) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [10, 100, 10, 80],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(staffRegister),

            styles: {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },

            info: {
                title: 'Staff Register',
            }
        }

        pushIntoTable(doc, staffRegister)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (staffRegister) => {
        return [
            {
                text:  'Staff Register',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                fontSize: 12,
                decoration: 'underline'
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: [],
                    widths: [ '10%', '40%', '40%', '10%' ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const pushIntoTable = (doc, staffRegister) => {
        const tableItems = staffRegister;
        const tableHeader = getTableHeader();
        doc.content[1].table.body.push(tableHeader);

        tableItems.forEach((item) => {
            const rowData = getRowData(item);
            return doc.content[1].table.body.push(rowData);
        });
    }

    const getTableHeader = () => {
        const tableHeader = [
            {text: 'Staff ID'},
            {text: 'Staff Name'},
            {text: 'Parent Name & ID'},
            {text: 'Generation'},
        ]

        return tableHeader
    }
    const getRowData = (item = {}) => {
        const tableRow = [
            { text: item.serial_no ?? '' },
            { text: item.name ?? '' },
            { text: (item.parent_name ?? '') + (item?.parent_serial_no ? ` (${item.parent_serial_no})` : '') },
            { text: item.level ?? '' },
        ];

        return tableRow;
    };

    return {
        exportToPDF
    }

}

export default pdfPrinter;