import Network from '@/services/network'

export default function () {
    const network = new Network;

    const fetchStaffRegister = (query) => {
        return network.api('get', '/sdm/get-staff-register' + query);
    }

    const fetchSaleAllocations = (query) => {
        return network.api('get', '/sdm/sale-allocations' + query);
    }

    const storeSaleAllocations = (data,query) => {
        return network.api('post', '/sdm/sale-allocations' + query,data);
    }

    return {
        fetchStaffRegister,
        fetchSaleAllocations,
        storeSaleAllocations
    }
}